export const strUcFirst = (a) => {
    a = a.toLowerCase()
    return (a+'').charAt(0).toUpperCase()+a.substr(1);
}
export const strLcFirst = (a) => {
    if(a?.length > 0){
        return (a+'').charAt(0).toLowerCase()+a.substr(1);
    }
}
export const strUppercCaase = (a) => {
    a = a.toUpperCase()

    return a
}


export const srtSubDescription = (text,number) => {
    text = text.substr(0, number)

    return text+"..."
}
export const strDate = (d) => {
    if(d?.length > 0){
        let _da = d.split("-")
    
        return _da[2].substr(0,2)+"/"+_da[1] +"/" +  _da[0]
    }
}

export const convertFromStringToDate = (responseDate) => {
    let dateComponents = responseDate.split('T');
    let datePieces = dateComponents[0].split("-");
    console.log("Date splitée :", datePieces)
    let timePieces = dateComponents[1].split(":");
    return (new Date(datePieces[0], (datePieces[1] - 1), datePieces[2],
        timePieces[0], timePieces[1], timePieces[2]))
}