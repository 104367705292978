import { HomeBodyProvider } from "../../layout/bodies/HomeBodyProvider"
import { HomeHeader } from "../../layout/headers/home/HomeHeader"

import './error404Page.css'


export const Error404Page = () => {


    return (<>
        <HomeBodyProvider children={<>
        <main>
            {/*  */}
            {/* =======================
Main banner START */}
            <section>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-10 text-center mx-auto">
                            {/* Image */}
                            <img src="images/error/error404.jpg " class="h-lg-400px mb-4 " alt="" />
                            <h2>Oh non, cette page n'est pas encore disponible !</h2>
                            {/* info */}
                            <p class="mb-4">Page en cours développemnt</p>
                            {/* Button */}
                            <a href="index-2.html" class="btn btn-light mb-0">Aller à l'accueil</a>
                        </div>
                    </div>
                </div>
            </section>
            {/* =======================
Main banner START */}

        </main>
        </>} />
    </>)
}