import './offerComponent.css'

export const OfferComponent = () => {

    return (<>
        {/*  */}
        {/* Offer card START */}
        <div className="container mt-4 offer-content">
            <div class="row mb-4">
                <div class="col-12 text-center">
                    <h3 class="mb-0">Nos services</h3>
                </div>
            </div>
            <div className="row">
                <div class="card col-md-4">
                    <img src="assets/images/offer/06.jpg" class="card-img offer-img" alt="" />
                    {/* Card body */}
                    <div class="position-absolute ttop start-50 translate-middle w-100 ">
                        <div class="card-body text-center bg-mode shadow rounded mx-4 p-3 cheight">
                            <h6 class="card-title mb-1"><a href="#">Covoiturage instantané</a></h6>
                            <small>Les automobilistes en teplacment actuel et qui souhaite avoir des passagers pour un covoiturage durant leur deplacement</small>
                            <div class="mt-2"><a href="#" class="btn btn-sm btn-dark mb-0">Voir plus</a></div>
                        </div>
                    </div>
                </div>
                <div class="card col-md-4">
                    <img src="assets/images/offer/06.jpg" class="card-img offer-img" alt="" />
                    {/* Card body */}
                    <div class="position-absolute ttop start-50 translate-middle w-100">
                        <div class="card-body text-center bg-mode shadow rounded mx-4 p-3 cheight">
                            <h6 class="card-title mb-1"><a href="#">Covoiturage programmé</a></h6>
                            <small>Des trajets programmer sur des trançons à des dates ultrieurs</small>
                            <div class="mt-2"><a href="#" class="btn btn-sm btn-dark mb-0">Voir plus</a></div>
                        </div>
                    </div>
                </div>
                <div class="card col-md-4">
                    <img src="assets/images/offer/06.jpg" class="card-img offer-img" alt="" />
                    {/* Card body */}
                    <div class="position-absolute ttop start-50 translate-middle w-100">
                        <div class="card-body text-center bg-mode shadow rounded mx-4 p-3 cheight">
                            <h6 class="card-title mb-1"><a href="#">Assistance</a></h6>
                            <small>Notre service est disponible pour vous assister au quotidien </small>
                            <div class="mt-2"><a href="#" class="btn btn-sm btn-dark mb-0">Voir plus</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    </>)
}