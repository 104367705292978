
import LanguageContextProvider from "../../contextLang";
import { HomeHeader } from "../headers/home/HomeHeader";


export function HomeBodyProvider({children}) {

    return(<LanguageContextProvider>
        <HomeHeader/>
        {children}
        
    </LanguageContextProvider>)
}