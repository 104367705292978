

export const Footer = () => {

    return(<>
    
<footer class="bg-dark pt-5">
	<div class="container">
		{/* Row START */}
		<div class="row g-4">

			{/* Widget 1 START */}
			<div class="col-lg-3">
				{/* logo */}
				<a href="index-2.html">
					<img class="h-70px" src="images/logo/logo.png" alt="logo"/>
				</a>
				<p class="my-3 text-muted">Slogan de Xryde</p>
				<p class="mb-2"><a href="#" class="text-muted text-primary-hover"><i class="bi bi-telephone me-2"></i>+1234 568 963</a> </p>
				<p class="mb-0"><a href="#" class="text-muted text-primary-hover"><i class="bi bi-envelope me-2"></i>example@gmail.com</a></p>
			</div>
			{/* Widget 1 END */}

			{/* Widget 2 START */}
			<div class="col-lg-8 ms-auto">
				<div class="row g-4">
					{/* Link block */}
					<div class="col-md-6">
						<h5 class="text-white mb-2 mb-md-4">Page</h5>
						<ul class="nav flex-column text-primary-hover">
							<li class="nav-item"><a class="nav-link text-muted" href="#">A propos</a></li>
							<li class="nav-item"><a class="nav-link text-muted" href="#">Contact</a></li>
							<li class="nav-item"><a class="nav-link text-muted" href="#">Aide</a></li>
						</ul>
					</div>

					{/* Link block */}
					<div class="col-md-6">
						<h5 class="text-white mb-2 mb-md-4">Link</h5>
						<ul class="nav flex-column text-primary-hover">
							<li class="nav-item"><a class="nav-link text-muted" href="#">Politique de confidentialité</a></li>
							<li class="nav-item"><a class="nav-link text-muted" href="#">Termes et conditions</a></li>
							<li class="nav-item"><a class="nav-link text-muted" href="#">Cookie</a></li>
							<li class="nav-item"><a class="nav-link text-muted" href="#">Support</a></li>
						</ul>
					</div>
				</div>
			</div>
			{/* Widget 2 END */}

		</div>{/* Row END */}

		{/* Payment and card */}
		<div class="row g-4 justify-content-between mt-0 mt-md-2">

			{/* Payment card */}
			<div class="col-sm-7 col-md-6 col-lg-4">
				<h5 class="text-white mb-2">Paiement & Sécurité</h5>
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a href="#"><img src="assets/images/element/paypal.svg" class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a href="#"><img src="assets/images/element/visa.svg" class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a href="#"><img src="assets/images/element/mastercard.svg" class="h-30px" alt=""/></a></li>
					<li class="list-inline-item"> <a href="#"><img src="assets/images/element/expresscard.svg" class="h-30px" alt=""/></a></li>
				</ul>
			</div>

			{/* Social media icon */}
			<div class="col-sm-5 col-md-6 col-lg-3 text-sm-end">
				<h5 class="text-white mb-2">Suivez-nous sur</h5>
				<ul class="list-inline mb-0 mt-3">
					<li class="list-inline-item"> <a class="btn btn-sm px-2 bg-facebook mb-0" href="#"><i class="fab fa-fw fa-facebook-f"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-instagram mb-0" href="#"><i class="fab fa-fw fa-instagram"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-twitter mb-0" href="#"><i class="fab fa-fw fa-twitter"></i></a> </li>
					<li class="list-inline-item"> <a class="btn btn-sm shadow px-2 bg-linkedin mb-0" href="#"><i class="fab fa-fw fa-linkedin-in"></i></a> </li>
				</ul>	
			</div>
		</div>

		{/* Divider */}
		<hr class="mt-4 mb-0"/>

		{/* Bottom footer */}
		<div class="row">
			<div class="container">
				<div class="d-lg-flex justify-content-between align-items-center py-3 text-center text-lg-start">
					{/* copyright text */}
					<div class="text-muted text-primary-hover"> Copyrights © 2023 Xryde. Tout droit réservé. </div>
					{/* copyright links*/}
					<div class="nav mt-2 mt-lg-0">
						<ul class="list-inline text-primary-hover mx-auto mb-0">
							<li class="list-inline-item me-0"><a class="nav-link py-1 text-muted" href="#">Politique de confidentialité</a></li>
							<li class="list-inline-item me-0"><a class="nav-link py-1 text-muted" href="#">Termes et conditions</a></li>
							<li class="list-inline-item me-0"><a class="nav-link py-1 text-muted pe-0" href="#">Politique de remboursement</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
    </>)
}