import { Link } from "react-router-dom"


export const AboutComponent = () => {

    return(<>
    
<section class="py-0 py-lg-7 mt-5">
	<div class="container">
        {/*  */}
		{/* Title */}
		<div class="row mb-4">
			<div class="col-12">
				<h2>A propos de Xryde ?</h2>
				<p class="mb-0">Xryde se veut d'aporter une solution pour facilité la mobilité des usagers de la route dans le district d’Abidjan et pourquoi pas partout ailleur en Côte d'Ivoire. </p>
			</div>
		</div>

		{/* Counters and features START */}
		<div class="row g-4">
			{/* Counter */}
			<div class="col-xl-5">
				<div class="card card-body bg-primary bg-opacity-10 vstack gap-4 justify-content-center h-100 p-4">
					{/* Counter item */}
					<div class="d-flex justify-content-between align-items-center">
						<h3 class="purecounter text-primary mb-0" data-purecounter-start="0" data-purecounter-end="10"	data-purecounter-delay="200">0</h3>
						<h6 class="fw-normal mb-0">Total usagers</h6>
					</div>

					{/* Counter item */}
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex justify-content-center me-3">
							<h3 class="purecounter text-primary mb-0" data-purecounter-start="0" data-purecounter-end="200"	data-purecounter-delay="300">0</h3>
							<span class="h3 text-primary mb-0">+</span>
						</div>
						<h6 class="fw-normal mb-0">Total conducteurs</h6>
					</div>

					{/* Counter item */}
					<div class="d-flex justify-content-between align-items-center">
						<div class="d-flex justify-content-center me-3">
							<h3 class="purecounter text-primary mb-0" data-purecounter-start="0" data-purecounter-end="50"	data-purecounter-delay="300">0</h3>
							<span class="h3 text-primary mb-0">+</span>
						</div>
						<h6 class="fw-normal mb-0">Total reservations</h6>
					</div>

				</div>
			</div>

			{/* Location */}
			<div class="col-md-6 col-xl-5">
				<div class="card  overflow-hidden">
					{/* Image */}
					<img src="assets/images/about/07.jpg" class="rounded-3" alt=""/>
					{/* Full screen button */}
					<div class="w-100 h-100">
						<Link to='/login' class="btn btn-dark position-absolute top-50 start-50 translate-middle mb-0" data-bs-toggle="modal" data-bs-target="#map360">
							<i class="fa-solid fa-sign-in me-2"></i> S'inscrire
						</Link>
					</div>
				</div>
			</div>

			{/* Features */}
			<div class="col-md-6 col-xl-2">
				<ul class="list-group list-group-borderless">
					<li class="list-group-item py-3">
						<h6 class="mb-0 fw-normal">
							<i class="bi bi-cash-coin fa-fw text-info me-2"></i>Facile à utiliser
						</h6>
					</li>

					<li class="list-group-item py-3">
						<h6 class="mb-0 fw-normal">
							<i class="bi bi-credit-card-2-back fa-fw text-warning me-2"></i>Rapide
						</h6>
					</li>

					<li class="list-group-item py-3">
						<h6 class="mb-0 fw-normal">
							<i class="bi bi-wallet fa-fw text-success me-2"></i>Utile
						</h6>
					</li>
				</ul>

			</div>
		</div>
		{/* Counters and features END */}
	</div>
</section>
    </>)
}