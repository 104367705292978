import { Link } from "react-router-dom"


export const RegisterUserPage = () => {

    return (<>
        <main>
            <section class="vh-xxl-100">
                <div class="container h-100 d-flex px-0 px-sm-4">
                    <div class="row justify-content-center align-items-center m-auto">
                        <div class="col-12">
                            <div class="bg-mode shadow rounded-3 overflow-hidden">
                                <div class="row g-0">
                                    {/* Vector Image */}
                                    <div class="col-lg-6 d-flex align-items-center order-2 order-lg-1">
                                        <div class="p-3 p-lg-5">
                                            <img src="images/auth/img1.jpg" alt="" />
                                            <Link to="/" className="btn btn-dark">Retour à l'accueil</Link>
                                        </div>
                                        {/* Divider */}
                                        <div class="vr opacity-1 d-none d-lg-block"></div>
                                        
                                    </div>

                                    {/* Information */}
                                    <div class="col-lg-6 order-1">
                                        <div class="p-4 p-sm-7">
                                            {/* Logo */}
                                            <a href="index-2.html">
                                                <img class="h-50px mb-4" src="images/logo/logo.png" alt="logo" />
                                            </a>
                                            {/* Title */}
                                            <h1 class="mb-2 h3">Créer un nouveau compte</h1>
                                            <p class="mb-0">Déjà membre?<Link to="/login"> Connectez-vous</Link></p>

                                            {/* Form START */}
                                            <form class="mt-4 text-start">
                                                {/* Email */}
                                                <div class="mb-3">
                                                    <label class="form-label">Entrer votre email</label>
                                                    <input type="email" class="form-control" />
                                                </div>
                                                {/* Password */}
                                                <div class="mb-3 position-relative">
                                                    <label class="form-label">Entrer votre mot de passe</label>
                                                    <input class="form-control fakepassword" type="password" id="psw-input" />
                                                    <span class="position-absolute top-50 end-0 translate-middle-y p-0 mt-3">
                                                        <i class="fakepasswordicon fas fa-eye-slash cursor-pointer p-2"></i>
                                                    </span>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Confirmez le mot de passe</label>
                                                    <input type="password" class="form-control" />
                                                </div>
                                                {/* Remember me */}
                                                <div class="mb-3 d-sm-flex justify-content-between">
                                                    <div>
                                                        <input type="checkbox" class="form-check-input" id="rememberCheck" />
                                                        <label class="form-check-label" for="rememberCheck">Gardez-moi connecté</label>
                                                    </div>
                                                </div>
                                                {/* Button */}
                                                <div><button type="submit" class="btn btn-primary w-100 mb-0">S'inscrire</button></div>

                                                {/* Divider */}
                                                <div class="position-relative my-4">
                                                    <hr />
                                                    <p class="small bg-mode position-absolute top-50 start-50 translate-middle px-2">Ou connectez-vous avec</p>
                                                </div>

                                                {/* Google and facebook button */}
                                                <div class="vstack gap-3">
                                                    <a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-google text-google-icon me-2"></i>Connectez-vous avec Google</a>
                                                    <a href="#" class="btn btn-light mb-0"><i class="fab fa-fw fa-facebook-f text-facebook me-2"></i>Connectez-vous avec Facebook</a>
                                                </div>

                                                {/* Copyright */}
                                                <div class="text-primary-hover mt-3 text-center"> Copyrights © 2023 Xryde. Tout droit réservé. </div>
                                            </form>
                                            {/* Form END */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>)
}