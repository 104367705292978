
import { AboutComponent } from "../../components/about/AboutComponent"
import { HomeBanner } from "../../components/banner/home/HomeBanner"
import { ContactComponent } from "../../components/contact/ContactComponent"
import { DownloadAppCOmponent } from "../../components/downloadApp/DownloadAppComponent"
import { OfferComponent } from "../../components/offer/OfferComponent"
import { TeamComponent } from "../../components/team/TeamComponent"
import { HomeBodyProvider } from "../../layout/bodies/HomeBodyProvider"
import { Footer } from "../../layout/footers/Footer"



export const HomePage = () => {
    return (<>
        <HomeBodyProvider children={<>
            <main>
                <HomeBanner />
                <OfferComponent />
                <AboutComponent />
                <TeamComponent />
                <ContactComponent />
                <DownloadAppCOmponent />
            </main>
            <Footer />
        </>} />
    </>)
}