import { createContext, useState } from "react";


export const LanguageContext = createContext();

const LanguageContextProvider = ({children}) => {
    const [language, setlanguage] = useState("fr");


    const switchLanguage = (upDateLang) => {
        setlanguage(upDateLang);
    }

    return(
        <LanguageContext.Provider value={{language,switchLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}

export default LanguageContextProvider;