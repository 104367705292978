

export const DownloadAppCOmponent = () => {

    return(<>
    <section class="bg-light">
	<div class="container">
		<div class="row g-4">
			{/* Help */}
			<div class="col-md-6 col-xxl-4">
				<div class="bg-body d-flex rounded-3 h-100 p-4">
					<h3><i class="fa-solid fa-hand-holding-heart"></i></h3>
					<div class="ms-3">
						<h5>24x7 Disponibilité en permanance</h5>
						<p class="mb-0">If we fall short of your expectation in any way, let us know</p>
					</div>
				</div>
			</div>

			{/* Trust */}
			<div class="col-md-6 col-xxl-4">
				<div class="bg-body d-flex rounded-3 h-100 p-4">
					<h3><i class="fa-solid fa-hand-holding-usd"></i></h3>
					<div class="ms-3">
						<h5>Plusieurs Moyens de payement</h5>
						<p class="mb-0">All refunds come with no questions asked guarantee</p>
					</div>
				</div>
			</div>

			{/* Download app */}
			<div class="col-lg-6 col-xl-5 col-xxl-3 ms-xxl-auto">
				<h5 class="mb-4">Téléchargez l'application</h5>
				<div class="row g-3">
					{/* Google play store button */}
					<div class="col-6 col-sm-4 col-md-3 col-lg-6">
						<a href="#"> <img src="assets/images/element/google-play.svg" alt=""/> </a>
					</div>
					{/* App store button */}
					<div class="col-6 col-sm-4 col-md-3 col-lg-6">
						<a href="#"> <img src="assets/images/element/app-store.svg" alt=""/> </a>
					</div>
				</div>
			</div>

		</div>
	</div>
</section>
    </>)
}