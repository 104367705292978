import { useEffect } from "react"

import './homeBanner.css'
import { Link } from "react-router-dom"


export const HomeBanner = () => {

    return (<>
        {/* ==Main Banner START */}
        <section class="py-0 mt-3">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-11 mx-auto">
                        {/* Slider START */}
                        <div id="carouselExampleDark" class="carousel slide " data-bs-ride="carousel">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner carousel-radius">
                                <div class="carousel-item active " data-bs-interval="10000">
                                    <img src="images/banner/bg111.png" class="d-block w-100 bs-height " alt="..."/>
                                        <div class="carousel-caption d-none d-md-block ">
											<h6 class="text-white fw-normal mb-0">Profitez d'un covoiturage réinventé orienté sur les particuliers</h6>
											<h1 class="text-white display-6">Le covoiturage autrement !</h1>
											<Link class="btn btn-primary mb-0 me-2">Publier une annonce</Link>
											<Link class="btn btn-warning mb-0"><i className="fa fa-download"></i> Telechargez l'application</Link>
                                        </div>
                                </div>
                                <div class="carousel-item" data-bs-interval="2000">
                                    <img src="images/banner/bg2.png" class="d-block w-100 bs-height" alt="..."/>
                                        <div class="carousel-caption d-none d-md-block">
											<h6 class="text-white fw-normal mb-0">Grâce à Xryde deplacez vous tout en economissant !</h6>
											<h1 class="text-white display-6">Vous proposer un service utile au quotidien !</h1>
											<Link class="btn btn-primary mb-0 me-2">S'inscrire</Link>
											<Link class="btn btn-warning mb-0"><i className="fa fa-download"></i> Telechargez l'application</Link>
                                        </div>
                                </div>
                                <div class="carousel-item">
                                <img src="images/banner/bg3.png" class="d-block w-100 bs-height" alt="..."/>
                                        <div class="carousel-caption d-none d-md-block">
											<h6 class="text-white fw-normal mb-0">Faites le c'est si simple que cela !</h6>
											<h1 class="text-white display-6">Téléchargez, recherchez, cliquez et réservez !</h1>
											<Link class="btn btn-primary mb-0 me-2">S'inscrire</Link>
											<Link class="btn btn-warning mb-0"><i className="fa fa-download"></i> Telechargez l'application</Link>
                                        </div>
                                </div>
                            </div>
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                        </div>
                        {/* Slider END */}
                    </div>
                </div>
            </div>
        </section>
        {/* =======================
Main Banner END */}
    </>)
}