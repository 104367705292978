

export const TeamComponent = () => {

    return(<>
    
<section>
	<div class="container">
        {/*  */}
		{/* Title */}
		<div class="row mb-4">
			<div class="col-12 text-center">
				<h2 class="mb-0">Notre équipe</h2>
			</div>
		</div>

		<div class="row g-4 g-md-5">
			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="https://lh3.googleusercontent.com/a-/AD_cMMQ5HM0D3JZ0ty5IaCcRPMT8JDnxMKFHDDJ-TnWiA336IP8=s48-p" class="rounded-circle" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Yao Kevin</a></h5>
						<h6>Fondateur de Xryde</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="https://scontent.fabj3-2.fna.fbcdn.net/v/t1.6435-9/99156892_888571434970379_1250946153088811008_n.jpg?stp=dst-jpg_p206x206&_nc_cat=101&cb=99be929b-3346023f&ccb=1-7&_nc_sid=da31f3&_nc_ohc=mYtuUVQLFr4AX8hB4k3&_nc_ht=scontent.fabj3-2.fna&oh=00_AfCDNczcy2p0bv2Fogv6iKsohii7sRdugzv_HcP42x2XGA&oe=64F8744C" class="rounded-circle" alt=""/>

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Gningninri Cedric</a></h5>
						<h6>Ingenieur développeur d'application</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="assets/images/category/hotel/nearby/03.jpg" class="rounded-circle" alt="" />

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Moustapha</a></h5>
						<h6>Ingenieur développeur d'application</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}

			{/* Card item START */}
			<div class="col-md-3">
				<div class="card bg-transparent text-center p-1 h-100">
					{/* Image */}
					<img src="https://scontent.fabj3-2.fna.fbcdn.net/v/t39.30808-6/273275867_1846614582393772_1198163132185537478_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=174925&_nc_eui2=AeF-V6vJUvbHC65k0WgJSPrWd6SCROZTiQt3pIJE5lOJC_82h13was1khyY6l9KWR4jlXdh0E4gojxwCPqPDIGVa&_nc_ohc=0PbkSO9UkUMAX_a4Jsl&_nc_ht=scontent.fabj3-2.fna&oh=00_AfB-4gDH4d-DyS96TnyMlBHwBYEI6CfGN1NHmZ40dXJNRA&oe=64D64047" class="rounded-circle" alt="" />

					<div class="card-body p-0 pt-3">
						<h5 class="card-title"><a href="#" class="stretched-link">M. Yao Parfait</a></h5>
						<h6>Développeur d'application Full-stack</h6>
					</div>
				</div>
			</div>
			{/* Card item END */}
		</div> {/* Row END */}
	</div>
</section>
    </>)
}